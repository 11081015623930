export const redirectAfterSubmit = () => {
    const forms = document.forms;

    for (const form of forms) {
        form.addEventListener('submit', async (event) => {
            event.preventDefault();

            const formData = new FormData(form);

            // const xhr = new XMLHttpRequest();

            // xhr.open('POST', '/');

            // // state change event
            // xhr.onreadystatechange = () => {
            //     // is request complete?
            //     if (xhr.readyState !== 4) return;

            //     if (xhr.status === 200) {
            //         // request successful
            //         console.log(xhr);
            //     } else {
            //         // request not successful
            //         console.log('HTTP error', xhr.status, xhr.statusText);
            //     }
            // };

            // // start request
            // xhr.send(formData);

            const response = await fetch('/', {
                method: 'POST',
                body: formData,
            });

            window.location.href = `${form.parentElement.dataset.redirectLink}`;

            // if (response.url.includes('send=true')) {
            //     window.location.href = `${form.parentElement.dataset.redirectLink}`;
            // }
        });
    }
};
