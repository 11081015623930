/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// CSS
import 'swiper/css';
import './vendor/fontawesome/css/all.min.css';
import './styles/index.scss';

// JavaScript
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

// Bootstrap Modules
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/collapse';

new Swiper('.swiper', {
    slidesPerView: 3,
    spaceBetween: 16,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 10,
        },
    },
    modules: [Navigation],
});

import './js/email_obfuscator';
import { navbar } from './js/navbar';
import { redirectAfterSubmit } from './js/form';
// import { dropdownHandler } from './js/dropdown';

(() => {
    navbar();
    // dropdownHandler();
    redirectAfterSubmit();
})();
